//DEV
export const firebaseConfig = {
    apiKey: "AIzaSyBnCaFFMcQUOAggJiuDqTyIAwJs_rxQeVQ",
    authDomain: "testimagedownload-c7093.firebaseapp.com",
    databaseURL: "https://testimagedownload-c7093.firebaseio.com",
    projectId: "testimagedownload-c7093",
    storageBucket: "testimagedownload-c7093.appspot.com",
    messagingSenderId: "766991091103",
    appId: "1:766991091103:web:87e7d36738fd8d857269aa",
    measurementId: "G-EMKR62FGCZ"
  };