const env = process.env.REACT_APP_ENV || 'dev';

const getConfig = () => {
  switch (env) {
    case 'sit':
      return require('./sit').firebaseConfig;
    case 'demo':
      return require('./demo').firebaseConfig;
    default:
      return require('./dev').firebaseConfig;
  }
};

export default getConfig();
