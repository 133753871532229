import React, { useState, useEffect } from 'react';
import database from '../firebase';
import { ref, get } from 'firebase/database';
import './styles/List.css';
import Pagination from './Pagination';

const ClerksList = ({ retailername }) => {
  const [clerks, setClerks] = useState([]);
  const [editedClerks, setEditedClerks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clerksPerPage] = useState(2); // Set the number of clerks to display per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clerksSnapshot = await get(ref(database, `retailers/${retailername}/clerks`));
        if (clerksSnapshot.exists()) {
          setClerks(Object.values(clerksSnapshot.val()));
        }
      } catch (error) {
        console.error('Error fetching clerks data:', error);
      }
    };

    fetchData();
  }, [retailername]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEdit = (clerkID) => {
    // Add the clerk to the editedClerks array when the "Edit" button is clicked
    setEditedClerks((prevEditedClerks) => [...prevEditedClerks, clerkID]);
  };

  const handleDone = (clerkID) => {
    // Remove the clerk from the editedClerks array when the "Done" button is clicked
    setEditedClerks((prevEditedClerks) => prevEditedClerks.filter((id) => id !== clerkID));
  };

  const handleCancel = (clerkID) => {
    // Remove the clerk from the editedClerks array when the "Cancel" button is clicked
    setEditedClerks((prevEditedClerks) => prevEditedClerks.filter((id) => id !== clerkID));
  };

  const handleSave = (clerkID, field, newValue) => {
    // Update the edited values locally
    const updatedClerk = { clerkID, [field]: newValue };
    setEditedClerks((prevEditedClerks) => {
      const index = prevEditedClerks.findIndex((clerk) => clerk.clerkID === clerkID);
      if (index !== -1) {
        // If the clerk is already in the editedClerks array, update its value
        return [
          ...prevEditedClerks.slice(0, index),
          updatedClerk,
          ...prevEditedClerks.slice(index + 1),
        ];
      } else {
        // If the clerk is not in the editedClerks array, add it
        return [...prevEditedClerks, updatedClerk];
      }
    });
  };

  const handleSubmitChanges = async () => {
    // TODO: Implement logic to submit changes to Firebase Realtime Database
    // You can use the editedClerks state to identify which clerks and fields were edited
    // Update the Firebase database with the new values
    console.log('Submitting changes to Firebase:', editedClerks);
  };

  return (
    <div>
      <h2>Clerks List</h2>
      <table className="list-table clerks-list-table">
        <thead>
          <tr>
            <th>Serial No</th>
            <th>ClerkID</th>
            <th>Username</th> {/* Include this line for the username */}
            <th>Device Type</th>
            <th>Is Online</th>
            <th>VoIP Token</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clerks
            .slice((currentPage - 1) * clerksPerPage, currentPage * clerksPerPage)
            .map((clerk, index) => (
              <tr key={clerk.clerkID}>
                <td>{index + 1}</td>
                <td>{clerk.clerkID}</td>
                <td>{clerk.username}</td> {/* Include the username here */}
                <td>
                  {editedClerks.includes(clerk.clerkID) ? (
                    <input
                      type="text"
                      value={clerk['device-type']}
                      onChange={(e) => handleSave(clerk.clerkID, 'device-type', e.target.value)}
                    />
                  ) : (
                    clerk['device-type']
                  )}
                </td>
                <td>
                  {editedClerks.includes(clerk.clerkID) ? (
                    <input
                      type="text"
                      value={clerk['is-online']}
                      onChange={(e) => handleSave(clerk.clerkID, 'is-online', e.target.value)}
                    />
                  ) : (
                    clerk['is-online'] ? 'Yes' : 'No'
                  )}
                </td>
                <td>
                  {editedClerks.includes(clerk.clerkID) ? (
                    <input
                      type="text"
                      value={clerk['voip-token']}
                      onChange={(e) => handleSave(clerk.clerkID, 'voip-token', e.target.value)}
                    />
                  ) : (
                    clerk['voip-token']
                  )}
                </td>
                <td>
                  {editedClerks.includes(clerk.clerkID) ? (
                    <>
                      <button onClick={() => handleDone(clerk.clerkID)}>Done</button>
                      <button onClick={() => handleCancel(clerk.clerkID)}>Cancel</button>
                    </>
                  ) : (
                    <button onClick={() => handleEdit(clerk.clerkID)}>Edit</button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        itemsPerPage={clerksPerPage}
        totalItems={clerks.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {/* Submit Changes Button */}
      {editedClerks.length > 0 && (
        <button onClick={handleSubmitChanges}>Submit Changes</button>
      )}
    </div>
  );
};

export default ClerksList;
