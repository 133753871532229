// Login.js
import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [retailername, setRetailername] = useState('');

  const handleLogin = () => {
    // Check if the input values match the predefined credentials
    const isCredentialsValid =
      username === 'superadmin' && password === 'a5ddccb9944f';

    if (isCredentialsValid) {
      // Call onLogin with retailername
      console.log('RetailerName');
      console.log(retailername);
      onLogin(retailername);
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <div>
        <label>Username:</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div>
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div>
        <label>Retailername:</label>
        <input type="text" value={retailername} onChange={(e) => setRetailername(e.target.value)} />
      </div>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
