import React, { useEffect, useState } from 'react';
import database from '../firebase';
import { ref, get } from 'firebase/database';
import Pagination from './Pagination';
import './styles/List.css';

const CallList = ({ retailername, searchTerm, searchColumn }) => {
  const [calls, setCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [callsPerPage] = useState(20);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const callsSnapshot = await get(ref(database, `retailers/${retailername}/calls`));
        if (callsSnapshot.exists()) {
          // Sort calls in descending order based on a timestamp (assuming a timestamp field in your data)
          const sortedCalls = Object.values(callsSnapshot.val()).sort(
            (a, b) => b.timestamp - a.timestamp
          );
          setCalls(sortedCalls);
        }
      } catch (error) {
        console.error('Error fetching calls data:', error);
      }
    };

    fetchData();
  }, [retailername]);

  useEffect(() => {
    // Filter calls based on the search term and column
    if (searchTerm && searchColumn) {
      const filtered = calls.filter((call) =>
        call[searchColumn].toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCalls(filtered);
      setCurrentPage(1); // Reset to the first page when filtering
    } else {
      setFilteredCalls([]);
    }
  }, [searchTerm, searchColumn, calls]);

  // Get current calls
  const indexOfLastCall = currentPage * callsPerPage;
  const indexOfFirstCall = indexOfLastCall - callsPerPage;
  const currentCalls =
    filteredCalls.length > 0
      ? filteredCalls.slice(indexOfFirstCall, indexOfLastCall)
      : calls.slice(indexOfFirstCall, indexOfLastCall);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h2>Call List</h2>

      {/* Display total number of calls */}
      <div className="total-calls-info">
        <p>Total Calls</p>
        <p>{filteredCalls.length > 0 ? filteredCalls.length : calls.length}</p>
      </div>

      <table className="list-table call-list-table">
        <thead>
          <tr>
            <th>Serial No</th>
            <th>CallUUID</th>
            <th>CallStatus</th>
            <th>ClerkID</th>
            <th>Token</th>
            <th>SessionID</th>
          </tr>
        </thead>
        <tbody>
          {currentCalls.map((call, index) => (
            <tr key={call.callUUID}>
              <td>{calls.length - (indexOfFirstCall + index)}</td>
              <td>{call.callUUID}</td>
              <td>{call.callStatus}</td>
              <td>{call.clerkID}</td>
              <td>{call.token}</td>
              <td>{call.sessionID}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {filteredCalls.length > 0 ? (
        <Pagination
          itemsPerPage={callsPerPage}
          totalItems={filteredCalls.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      ) : (
        <Pagination
          itemsPerPage={callsPerPage}
          totalItems={calls.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default CallList;
