// App.js
import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [retailername, setRetailername] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleLogin = (enteredRetailername) => {
    // Your authentication logic here (compare username, password, and retailername)
    // For simplicity, using hardcoded values
    const username = 'superadmin';
    const password = 'a5ddccb9944f';

    console.log('Code received here');
      console.log(enteredRetailername);

    // Assuming you have a more secure authentication logic in your actual application
    if (username === 'superadmin' && password === 'a5ddccb9944f') {
      setIsAuthenticated(true);
      const retailername = enteredRetailername
      setRetailername(enteredRetailername); // Set the retailername
      navigate('/dashboard', { state: { retailername } }); // Redirect to dashboard upon successful login
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  return (
    <div>
      <h1>Retailer Admin Panel</h1>
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard location={location} retailername={retailername} />} // Pass retailername to Dashboard
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default App;
