//SIT
export const firebaseConfig = {
        apiKey: "AIzaSyCSwIuRdxBf0BDuCxrDIsdEVX6bMGoDc9E",
        authDomain: "wakapapa-fd7cb.firebaseapp.com",
        databaseURL: "https://wakapapa-fd7cb-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "wakapapa-fd7cb",
        storageBucket: "wakapapa-fd7cb.appspot.com",
        messagingSenderId: "589664048784",
        appId: "1:589664048784:web:2609e1fa5eaf4a3eb16a15",
        measurementId: "G-LDPJSX6ZQM"
      };