import React, { useState, useEffect } from 'react';
import CallList from './CallList';
import ClerksList from './ClerksList';
import './styles/Dashboard.css'; // Import the dashboard styles

const Dashboard = ({ location }) => {
  const retailername = location?.state?.retailername;

  const [selectedComponent, setSelectedComponent] = useState('Calls');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchColumn, setSearchColumn] = useState('');
  const [loading, setLoading] = useState(false);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
    setSearchTerm(''); // Clear search term when changing components
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchColumnChange = (e) => {
    setSearchColumn(e.target.value);
    setSearchTerm(''); // Clear search term when changing search column
  };

  const handleCancelSearch = () => {
    setSearchTerm('');
  };

  const handleRefresh = () => {
    // Set loading to true while fetching data
    setLoading(true);

    // Simulate data fetching with a delay (you can replace this with your actual data fetching logic)
    setTimeout(() => {
      // Set loading to false once data is fetched
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    // Fetch initial data when the component mounts (you can replace this with your actual data fetching logic)
    handleRefresh();
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div>
      <div className="dashboard-header">
        <h2>Welcome to the Dashboard, {retailername || 'User'}!</h2>
        <button onClick={() => handleComponentChange('Calls')}>Calls</button>
        <button onClick={() => handleComponentChange('Clerks')}>Clerks</button>

        {/* Refresh button */}
        <button onClick={handleRefresh}>Refresh</button>

        {/* Search functionality */}
        <div className="search-container">
          <select onChange={handleSearchColumnChange} value={searchColumn}>
            <option value="">Select Search Term</option>
            {selectedComponent === 'Calls' && (
              <>
                <option value="callUUID">CallUUID</option>
                <option value="sessionID">SessionID</option>
                <option value="token">Token</option>
              </>
            )}
            {selectedComponent === 'Clerks' && (
              <option value="voip-token">VoIP Token</option>
            )}
          </select>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {searchTerm && (
            <button onClick={handleCancelSearch}>Cancel Search</button>
          )}
        </div>
      </div>

      <div className="dashboard-content">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {selectedComponent === 'Calls' && (
              <CallList retailername={retailername} searchTerm={searchTerm} searchColumn={searchColumn} />
            )}
            {selectedComponent === 'Clerks' && (
              <ClerksList retailername={retailername} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
